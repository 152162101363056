import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import GravityFormForm from "gatsby-plugin-gravity-forms";
import "@assets/css/gravityForm.scss";

export const Form = (data) => {
	return (
		<Container id={data.id}>
			<Row>
				<Col>
					<GravityFormForm data={data} />
				</Col>
			</Row>
		</Container>
	);
};

Form.propTypes = {
	id: PropTypes.any.isRequired,
	formId: PropTypes.number,
};

import React, { useEffect, useState } from "react";
import Banner from "@components/Banner";
import Layout from "@parts/Layout";
import Hero from "@parts/Hero";
import LogoSlider from "@parts/LogoSlider";
import ParagraphTwoColumns from "@parts/ParagraphTwoColumns";
import ParagraphWithMedia from "@parts/ParagraphWithMedia";
import RequestDemo from "@parts/RequestDemo";
import Testimonial from "@parts/Testimonial";
import Form from "@parts/Form";
import Seo from 'gatsby-plugin-wpgraphql-seo';

const PageTemplate = ({ pageContext }) => {
	const [data, setData] = useState([]);
	const [form, setForm] = useState([]);

	useEffect(() => {
		for (let page of pageContext.flexContent.edges) {
			if (page.node.ACF_PageTemplate && page.node.id === pageContext.id) {

				// Set flex content data
				setData(page.node.ACF_PageTemplate.flexContent);

				// Set form data
				for (let element of page.node.ACF_PageTemplate.flexContent) {
					if (element.fieldGroupName === 'Page_AcfPagetemplate_FlexContent_Form') {

						for (let form of pageContext.formData.allWpGfForm.edges) {

							// formId is devined in Wordpress page via form element
							if (form.node.databaseId === element.formId) {

								// Add form settings to object for reCaptcha
								let formObj = {
									...form.node,
									...pageContext.formSettings
								}
								
								setForm(formObj);
							}
						}
					}
				}
			}
		}
	}, [pageContext, data]);

	return (
		<Layout>
			<Seo post={pageContext} />
			
			{data &&
				data.map((element, i) => {
					let splittedName = element.fieldGroupName.split("_");

					// Remove first item from splitted array
					splittedName.shift();

					let fieldGroupName = splittedName.join("_");

					switch (fieldGroupName) {
						case "AcfPagetemplate_FlexContent_HeroImage":
							return <Hero key={i + 1} id={`hero-image-${i + 1}`} {...element} />;
						case "AcfPagetemplate_FlexContent_LogoSlider":
							return <LogoSlider key={i + 1} id={`logo-slider-${i + 1}`} {...element} />;
						case "AcfPagetemplate_FlexContent_RequestDemo":
							return <RequestDemo key={i + 1} id={`request-demo-${i + 1}`} {...element} />;
						case "AcfPagetemplate_FlexContent_ParagraphWithMedia":
							return <ParagraphWithMedia key={i + 1} id={`paragraph-image-${i + 1}`} {...element} />;
						case "AcfPagetemplate_FlexContent_ParagraphTwoColumns":
							return <ParagraphTwoColumns key={i + 1} id={`paragraph-two-columns-${i + 1}`} {...element} />;
						case "AcfPagetemplate_FlexContent_Banner":
							return <Banner key={i + 1} id={`banner-${i + 1}`} {...element} />;
						case "AcfPagetemplate_FlexContent_Testimonial":
							return <Testimonial key={i + 1} id={`testimonial-${i + 1}`} {...element} />;
						case "AcfPagetemplate_FlexContent_Form":
							return <Form key={i + 1} id={`form-${i + 1}`} {...element} {...form} />;
						default:
							return "";
					}
				})}
		</Layout>
	);
};

export default PageTemplate;

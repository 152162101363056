import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import Button from "@components/Button";
import * as styles from "./ParagraphTwoColumns.module.scss";

export const ParagraphTwoColumns = ({ id, title, text, button }) => {
	return (
		<Container id={id} className={`element container--small`}>
			{title && <h2 className={styles.paragraphTwoColumns__title}>{title}</h2>}
			{text && <p className={styles.paragraphTwoColumns__text}>{text}</p>}
			{button && (
				<Button
					url={button.url}
					title={button.title}
					target={button.target}
					type="primary"
					cssClass={styles.paragraphTwoColumns__button}
				/>
			)}
		</Container>
	);
};

ParagraphTwoColumns.propTypes = {
	id: PropTypes.any.isRequired,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	button: PropTypes.object,
};

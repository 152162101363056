import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container } from "react-bootstrap";
import Button from "@components/Button";
import "./RequestDemo.scss";

export const RequestDemo = ({ id, bgColor, title, text, button, image }) => {
	return (
		<div id={id} className={`element request-demo ${bgColor}`}>
			<Container>
				{title && (
					<div className="request-demo__content">
						<h2 className="request-demo__content__title">{title}</h2>

						{text && <div className="request-demo__content__text">{text}</div>}

						{button &&
							(bgColor === "dark" ? (
								<Button
									url={button.url}
									title={button.title}
									target={button.target}
									type="secondary"
									cssClass="request-demo__content__button"
								/>
							) : (
								<Button
									url={button.url}
									title={button.title}
									target={button.target}
									type="primary"
									cssClass="request-demo__content__button"
								/>
							))}
					</div>
				)}

				{image && (
					<div className="request-demo__image">
						<GatsbyImage image={image.localFile.childImageSharp.gatsbyImageData} alt={image.altText} />
					</div>
				)}
			</Container>
		</div>
	);
};

RequestDemo.propTypes = {
	id: PropTypes.any.isRequired,
	bgColor: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	button: PropTypes.object,
	image: PropTypes.object,
};

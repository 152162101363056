import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import Icon, { QuoteIcon } from "@components/Icon";
import * as styles from "./Testimonial.module.scss";

export const Testimonial = ({ id, author, text }) => {
	return (
		<Container id={id} className="element">
			<Row className={styles.testimonial__row}>
				<Col xs={1} className={`${styles.testimonial__quote}`}>
					<Icon className={styles.testimonial__quote__icon} icon={QuoteIcon} />
				</Col>
				<Col className={styles.testimonial__content}>
					{author && <h4 className={styles.testimonial__content__author}>{author}</h4>}
					{text && <h3 className={styles.testimonial__content__text}>{text}</h3>}
				</Col>
				<Col xs={1} className={`${styles.testimonial__quote} ${styles.testimonial__quote__right}`}>
					<Icon className={`${styles.testimonial__quote__icon} ${styles.testimonial__quote__right__icon}`} icon={QuoteIcon} />
				</Col>
			</Row>
		</Container>
	);
};

Testimonial.propTypes = {
	id: PropTypes.any.isRequired,
	author: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import Button from "@components/Button";

import "./ParagraphWithMedia.scss";

export const ParagraphWithMedia = ({ id, subTitle, title, text, button, alignment, width, imageOrVideo, image, videoSrc, autoplay, loop, muted, controls }) => {
	return (
		<Container
			id={id}
			className={`element paragraph-media container${width === "medium" ? "--medium" : "--small"} ${alignment}`}>
			<Row className="justify-content-between align-items-center">
				<Col className="blockquote" />

				<Col className="col__1" xs={{ order: "last" }} lg={{ span: 6, order: "first" }}>
					{imageOrVideo === "image" && (
						image && (
							<div className="paragraph-media__image">
								{button ? (
									<a href={button.url}>
										<GatsbyImage
											image={image.localFile.childImageSharp.gatsbyImageData}
											alt={image.altText}
										/>
									</a>
								) : (
									<GatsbyImage
										image={image.localFile.childImageSharp.gatsbyImageData}
										alt={image.altText}
									/>
								)}
							</div>
						)
					)}

					{imageOrVideo === "video" && (
						videoSrc && (
							<div className="paragraph-media__video">
								<video autoPlay={autoplay} loop={loop} muted={muted} controls={controls} src={videoSrc}></video>
							</div>
						)
					)}
				</Col>

				<Col className="col__2" lg={6}>
					{title && (
						<div className="paragraph-media__content">
							{subTitle && <h4 className="paragraph-media__content__subtitle">{subTitle}</h4>}

							{width === "medium" ? (
								<h2 className="paragraph-media__content__title">{title}</h2>
							) : (
								<h3 className="paragraph-media__content__title">{title}</h3>
							)}

							{text && <div className="paragraph-media__content__text">{text}</div>}

							{button && (
								<Button
									url={button.url}
									title={button.title}
									target={button.target}
									type="primary"
									cssClass="paragraph-media__content__button"
								/>
							)}
						</div>
					)}
				</Col>
			</Row>
		</Container>
	);
};

ParagraphWithMedia.propTypes = {
	id: PropTypes.any.isRequired,
	subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	button: PropTypes.object,
	alignment: PropTypes.oneOf(["left", "center", "right"]),
	width: PropTypes.oneOf(["small", "medium", "large"]),
	imageOrVideo: PropTypes.oneOf(["image", "video"]),
	image: PropTypes.object,
	videoSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	autoplay: PropTypes.bool,
	loop: PropTypes.bool,
	muted: PropTypes.bool,
	controls: PropTypes.bool
};

ParagraphWithMedia.defaultProps = {
	alignment: "left",
	width: "medium",
};
